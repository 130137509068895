










import Vue from "vue";
import moment from "moment";

export default Vue.extend({
  name: "Home",
  computed: {
    daysUntil() {
      const now = moment().startOf("day");
      const redEyeDate = moment("2023-05-17", "YYYY-MM-DD");
      return redEyeDate.diff(now, "days");
    }
  }
});
